import React from 'react'
import { css } from '@emotion/react'
import { StaticLayout } from '../components/StaticLayout'
import { Footer } from '../components/Footer'
import styled from '@emotion/styled'
import { Typography } from 'antd'
const { Paragraph } = Typography

const styles = css`
  body {
    padding: 0;
    margin: 0;
    /*gradient light color for faq section*/
    background-color: #fdfaf4;
  }

  #navbar {
    height: 50px;
    border-bottom: 2px solid #ccc;
    clear: both;
    background-color: #eee;
  }

  #logolink {
    float: left;
    height: 38px;
    margin-top: 7px;
    margin-left: 10px;
  }

  #logo {
    height: 38px;
  }

  #close {
    float: right;
    margin-right: 10px;
    margin-top: 7px;
    text-decoration: none;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: black;
    height: 40px;
    padding: 10px;
  }

  .static-content {
    padding: 0px 15px 15px 15px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.4em;

    text-align: left;
    max-width: 700px;
    margin: auto;
  }

  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #000066;
    cursor: default;
    font-size: 30px;
    font-weight: 500;
    margin-top: 10px;
  }

  h3 {
    font-size: 22px;
    line-height: 1.4em;
    font-weight: 500;
    text-align: center;
  }
`

const PricingLink = styled.a`
  color: #fff;

  &:active,
  &:hover {
    color: #fff;
  }
`

const Pricing = () => (
  <StaticLayout>
    <div css={styles}>
      <div id="navbar">
        <a id="logolink" href="https://www.stayfari.com">
          <img
            id="logo"
            src="https://ik.imagekit.io/stayfari/general/tr:w-160,h-48,c-maintain_ratio/FL_Norwester_Elbaris_Hybrid-01_LlKpdcqksp.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666155398553"
            alt="Stayfari Logo"
          />
        </a>
        {/* <GetStartedButton /> */}
        <a id="close" href="javascript:window.close()">
          Close
        </a>
      </div>
      <div className="static-content">
        <h2>Stayfari Principles</h2>
        <h3>Pricing, Services &amp; Cancellation</h3>
        <Paragraph>
          1. Our instantly online quotes are all-inclusive of tolls, parking, taxes and driver
          expenses. Unless you add a significant detour to your itinerary or exceed the daily
          limits, the quote you get upfront is all you&#39;ll be asked to pay.
        </Paragraph>

        <Paragraph>
          2. We do away with hourly and per kilometer rates. You&#39;re free to use the vehicle at
          any time of day, up to eight hours when driving locally and 12 hours outstation (between
          cities). Within any city in your itinerary, your mileage limit is eighty kilometers per
          day. Itineraries are not required in Mauritius or Goa.
        </Paragraph>

        <Paragraph>
          3. A Stayfari representative will communicate with you before and during your trip. They
          will understand your trip in detail and alert you if your intended use cannot be supported
          or may trigger extra charges. Their role is to ensure your trip proceeds smoothly and that
          you are fully satisfied with the experience.
        </Paragraph>

        <Paragraph>
          4. You&#39;ll have a dedicated English-speaking driver and air-conditioned vehicle for
          your entire trip. Drivers competitively salaried and provided an extra allowance to pay
          for their room and board on nights spent away from home.
        </Paragraph>

        <Paragraph>
          5. Drivers are vaccinated and wear masks. Vehicles are disinfected daily and hand
          sanitizer is provided for your use. We&#39;ll gladly install a partition screen between
          the front and back of your vehicle upon request.
        </Paragraph>

        <Paragraph>
          6. We do not make money on your trip aside from charging you for our services. We
          incentivize and require partner operators, drivers and local guides to do the same.
        </Paragraph>

        <Paragraph>
          7. All payments are made securely online. In general, we require a 50% deposit upfront and
          the balance is due halfway through your trip. Reservations made for less than three days
          or within 24 hours of pickup require payment upfront in full.
        </Paragraph>

        <Paragraph>
          8. We encourage you to pay in the currency most convenient for you. We do not pass our
          currency conversion costs onto customers. Depending on the currency you select, available
          payment methods include credit/debit cards, UPI, Netbanking, bank transfers, Paypal, Apple
          Pay and many Indian e-wallets.
        </Paragraph>

        <Paragraph>
          9. During your trip, we&#39;re just a WhatsApp, text message, email, or phone call away.
          We respond swiftly to resolve any issues or handle any changes in your plan.
        </Paragraph>

        <Paragraph>
          10. Cancellations made at least 24 hours before pickup are refunded in full, no questions
          asked. Late cancellations are refunded to the best of our ability without being unfair to
          drivers. We will process your refund within three business days, but depending on your
          bank, funds may take up to ten business days to be credited to your account.
        </Paragraph>

        <Paragraph>
          11. We obsess over customer feedback, using it to continuously improve the services we
          provide. Please read our{' '}
          <PricingLink href="https://www.tripadvisor.com/ShowUserReviews-g304551-d16881114-r942854288-Stayfari-New_Delhi_National_Capital_Territory_of_Delhi.html#REVIEWS">
            reviews on TripAdvisor
          </PricingLink>{' '}
          to see what our customers have had to say.
        </Paragraph>
      </div>
    </div>
    <Footer />
  </StaticLayout>
)

export default Pricing
